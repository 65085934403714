/* eslint-disable no-fallthrough */
/* eslint-disable no-unreachable */
import CryptoJS from "crypto-js";

// import Localbase from 'localbase';


// Step 1: Create and initialize the IndexedDB database
// import { openDB } from 'idb';

// const dbName = 'my-users-db';
// var idb = new Localbase(dbName);
// const dbVersion = 1;
// const objectStoreName = 'users';

// const initializeDB = async () => {
//   //console.log('initialdb')
//   const db = await openDB(dbName, dbVersion, {
//     upgrade(db) {
//       if (!db.objectStoreNames.contains('users')) {
//         db.createObjectStore("users", { keyPath: 'id', uniq: true });
        
//       }
//       if (!db.objectStoreNames.contains('messages')) {
//         const messageStore = db.createObjectStore("messages", { keyPath: [ 'msg_id','conversation_id']});
//         messageStore.createIndex('conversation_id', 'conversation_id', { unique: false });
//         messageStore.createIndex('msg_id', 'msg_id', { unique: false });
        
//       }
//       if (!db.objectStoreNames.contains('message_single')) {
//         db.createObjectStore("message_single", { keyPath: 'msg_id', uniq: true });
        
//       }
//       if (!db.objectStoreNames.contains('replay_messages')) {
//         const replymessageStore = db.createObjectStore("replay_messages", { keyPath: [ 'msg_id','reply_for_msgid']});
//         replymessageStore.createIndex('reply_for_msgid', 'reply_for_msgid', { unique: false });
//         replymessageStore.createIndex('msg_id', 'msg_id', { unique: false });
        
//       }
//       if (!db.objectStoreNames.contains('conversations')) {
//         db.createObjectStore("conversations", { keyPath: 'conversation_id', uniq: true });
        
//       }
//       if (!db.objectStoreNames.contains('my_user')) {
//         db.createObjectStore("my_user", { keyPath: 'id', uniq: true });
        
//       }
//     },
//   });

//   return db;
// };

// // Step 2: Perform CRUD operations
// const db = initializeDB();

// const saveUser = async (userData) => {
//   const tx = (await db).transaction(objectStoreName, 'readwrite');
//   const store = tx.objectStore(objectStoreName);
//   let checkexist = await store.getAll();
//   if(checkexist){
//     checkexist = checkexist.map(v=> v.id)
//   }else{
//     checkexist = [];
//   }
//   for(let i of userData){
//     let d = await aesEncrypt(i);
//     if(checkexist.indexOf(i.id) == -1){
//       await store.add({id:i.id,value:d});
//     }else{
//       await store.put({id:i.id,value:d});
//     }
    
//   }
  
//   await tx.complete;
// };
// export const saveMyuser_idb = async (myuser) => {
//   const tx = (await db).transaction('my_user', 'readwrite');
//   const store = tx.objectStore('my_user');
//   let d = await aesEncrypt(myuser);
//   const checkexist = await store.get(myuser.id);
//   if(checkexist){
//     await store.put({id:myuser.id,value:d});
//   }else{
//     await store.add({id:myuser.id,value:d});
//   }
  
  
//   await tx.complete;
// };

// export const removeIDBdatabase = ()=>{

//   idb.delete().then(() => {
//     //console.log('All data deleted from the database.');
//   }).catch((error) => {
//     console.error('Error deleting data:', error);
//   });
// }

// export const saveMessages = async (messages) => {
//   const tx = (await db).transaction('messages', 'readwrite');
//   const store = tx.objectStore('messages');

//   // Clear the object store before adding the new data to maintain a single row
//   // await store.clear();
//   for(let i of messages){
//     await store.add(i);
//   }
  
//   await tx.complete;
// };
// // Function to get a conversation by its conversation_id from the 'conversations' Object Store
// export const getConversationById_idb = async (conversationId) => {
//   const tx = (await db).transaction('conversations', 'readonly');
//   const store = tx.objectStore('conversations');
//   const request = await store.get(conversationId);
//   if(request){
//     let val = await decryptedData(request.value);
//     return {conversation_id:conversationId,...val}
//   }else{
//     return false
//   }
  
// };
// // Function to get a conversation by its conversation_id from the 'conversations' Object Store
// export const getReplaySingleById_idb = async (msgid) => {
//   const tx = (await db).transaction('message_single', 'readonly');
//   const store = tx.objectStore('message_single');
//   const request = await store.get(msgid);
//   if(request){
//     let val = await decryptedData(request.value);
//     return {msg_id:request.msg_id,...val}
//   }else{
//     return false
//   }
  
// };
// export const getmyUser_idb = async () => {
//   const tx = (await db).transaction('my_user', 'readonly');
//   const store = tx.objectStore('my_user');
//   const request = await store.getAll();
//   if(request.length > 0){
//     let val = await decryptedData(request[0].value);
//     return val;
//   }else{
//     return {}
//   }
  
// };
// function sortByCreatedAt(dataArray) {
//   return dataArray.sort((a, b) => {
//     const dateA = new Date(a.created_at); // Convert to Date objects for comparison
//     const dateB = new Date(b.created_at);

//     return dateA - dateB; // Ascending order
//     // For descending order, use return dateB - dateA;
//   });
// }
// export const getMessagesById_idb = async (conversationId) => {
//   const tx = (await db).transaction('messages', 'readonly');
//   const store = tx.objectStore('messages');
//   const index = store.index('conversation_id'); // If you have created an index on 'conversationId'
//   const keyRange = IDBKeyRange.only(conversationId);
//   let request = await index.getAll(keyRange);
//   let data = [];
//   if(request.length > 0){
    
//     for (let i of request){
//       let val = await decryptedData(i.value);
//       //console.log(136,val)
//       data.push({msg_id:i.msg_id,conversation_id:i.conversation_id,...val})
//     }
//     data = data.filter(v=> v.indexdb_delete !== true)
//   }
//   return data ? sortByCreatedAt(data):false;
// };
// export const getreplayMessagesById_idb = async (reply_for_msgid) => {
//   const tx = (await db).transaction('replay_messages', 'readonly');
//   const store = tx.objectStore('replay_messages');
//   const index = store.index('reply_for_msgid'); // If you have created an index on 'reply_for_msgid'
//   const keyRange = IDBKeyRange.only(reply_for_msgid);
//   let request = await index.getAll(keyRange);
//   let data = [];
//   if(request.length > 0){
    
//     for (let i of request){
//       let val = await decryptedData(i.value);
//       //console.log(136,val)
//       data.push({msg_id:i.msg_id,reply_for_msgid:i.reply_for_msgid,...val})
//     }
//     data = data.filter(v=> v.indexdb_delete !== true)
//   }
//   return data ? sortByCreatedAt(data):false;
// };

// export const saveConversation_idb = async (conversation) => {
//   const tx = (await db).transaction('conversations', 'readwrite');
//   const store = tx.objectStore('conversations');
//   const checkexist = await store.get(conversation.conversation_id);
//   let d = await aesEncrypt(conversation);
//   if(checkexist){
//     await store.put({conversation_id:conversation.conversation_id,value:d});
//   }else{
//     try{
//       await store.add({conversation_id:conversation.conversation_id,value:d});
//     }catch(error){
//       //console.log(error)
//     }
    
//   }
//   await tx.complete;
// };
// export const saveSingleReplay_idb = async (conversation) => {
//   
//   const tx = (await db).transaction('message_single', 'readwrite');
//   const store = tx.objectStore('message_single');
//   const checkexist = await store.get(conversation.msg_id);
//   let d = await aesEncrypt(conversation);
//   if(checkexist){
   
//     await store.put({msg_id:conversation.msg_id,value:d});
//   }else{
//     try{
//       await store.add({msg_id:conversation.msg_id,value:d});
//     }catch(error){
//       //console.log(error)
//     }
    
//   }
//   await tx.complete;
// };
// export const reply_saveMessages_idb = async (messages,clear = '') => {
//   if(messages.length > 0){
//     //console.log(163,clear)
//     if(clear == 'clear'){
      
//       return new Promise((resolve, reject) => {
//         var request = indexedDB.open(dbName, 1);
//         request.onupgradeneeded = function (event) {
//             var db = event.target.result;
//             // Create an object store (similar to a table in a relational database)
//             var objectStore = db.createObjectStore('replay_messages', { keyPath: ['reply_for_msgid', 'msg_id'] });
//             // Create an index for searching by another property (e.g., 'name')
//             objectStore.createIndex('reply_for_msgid', 'reply_for_msgid', { unique: false });
//             objectStore.createIndex('msg_id', 'msg_id', { unique: false });
//         };
//         request.onsuccess = function (event) {
//           var db = event.target.result;
    
//             // Start a transaction and get the object store
//             var transaction = db.transaction(['replay_messages'], 'readwrite');
//             var objectStore = transaction.objectStore('replay_messages');
//             // Delete data using an index
//             var index = objectStore.index('reply_for_msgid');
//             var deleteRequest = index.openCursor(IDBKeyRange.only(messages[0].reply_for_msgid));
    
//             deleteRequest.onsuccess = function (event) {
//                 var cursor = event.target.result;
//                 if (cursor) {
//                     cursor.delete();
//                     cursor.continue();
//                 }
//             };
    
//             transaction.oncomplete = function () {
//                 //console.log('idx:Deletion completed');
//                 var request = indexedDB.open(dbName, 1);
//                 //console.log(request)
//                 // Handle database upgrade event
//                 request.onupgradeneeded = function (event) {
//                     var db = event.target.result;
//                     // Create an object store (similar to a table in a relational database)
//                     var objectStore = db.createObjectStore('replay_messages', { keyPath: ['reply_for_msgid', 'msg_id'] });
//                     // Create an index for searching by another property (e.g., 'name')
//                     objectStore.createIndex('reply_for_msgid', 'reply_for_msgid', { unique: false });
//                     objectStore.createIndex('msg_id', 'msg_id', { unique: false });
//                 };

//                 // Handle successful database opening
//                 request.onsuccess = async function (event) {
//                   //console.log(220,event)
//                     var db = event.target.result;
//                     // Start a transaction and get the object store
//                     var transaction = db.transaction(['replay_messages'], 'readwrite');
//                     var objectStore = transaction.objectStore('replay_messages');
//                     for(let i of messages){
//                       let d = await aesEncrypt(i);
//                       objectStore.put({msg_id:i.msg_id,reply_for_msgid:i.reply_for_msgid,value:d});
                      
//                     }

//                     // Commit the transaction
//                     transaction.oncomplete = function () {
//                         resolve(true)
//                         //console.log('idx:Data inserted successfully');
//                     };
//                 };
                
//             };
//             transaction.onerror = function (error) {
//               //console.log(error);
//               resolve(true)
//           };
      
//         };
//       });
//     }else{
//       return new Promise((resolve, reject) => {
//         var request = indexedDB.open(dbName, 1);
//           //console.log(request)
//           // Handle database upgrade event
//           request.onupgradeneeded = function (event) {
//               var db = event.target.result;
//               // Create an object store (similar to a table in a relational database)
//               var objectStore = db.createObjectStore('replay_messages', { keyPath: ['reply_for_msgid', 'msg_id'] });
//               // Create an index for searching by another property (e.g., 'name')
//               objectStore.createIndex('reply_for_msgid', 'reply_for_msgid', { unique: false });
//               objectStore.createIndex('msg_id', 'msg_id', { unique: false });
//           };

//           // Handle successful database opening
//           request.onsuccess = async function (event) {
//             //console.log(220,event)
//               var db = event.target.result;
//               // Start a transaction and get the object store
//               var transaction = db.transaction(['replay_messages'], 'readwrite');
//               var objectStore = transaction.objectStore('replay_messages');
//               for(let i of messages){
//                 let d = await aesEncrypt(i);
//                 objectStore.put({msg_id:i.msg_id,reply_for_msgid:i.reply_for_msgid,value:d});
//                 // objectStore.put(i);
                
//               }

//               // Commit the transaction
//               transaction.oncomplete = function () {
//                   resolve(true)
//                   //console.log('idx:Data inserted successfully');
//               };
//           };
//       });
//     }

    
//   }
// }
// export const saveMessages_idb = async (messages,clear = '') => {
//   if(messages.length > 0){
//     //console.log(163,clear)
//     if(clear == 'clear'){
      
//       return new Promise((resolve, reject) => {
//         var request = indexedDB.open(dbName, 1);
//         request.onupgradeneeded = function (event) {
//             var db = event.target.result;
//             // Create an object store (similar to a table in a relational database)
//             var objectStore = db.createObjectStore('messages', { keyPath: ['conversation_id', 'msg_id'] });
//             // Create an index for searching by another property (e.g., 'name')
//             objectStore.createIndex('conversation_id', 'conversation_id', { unique: false });
//             objectStore.createIndex('msg_id', 'msg_id', { unique: false });
//         };
//         request.onsuccess = function (event) {
//           var db = event.target.result;
    
//             // Start a transaction and get the object store
//             var transaction = db.transaction(['messages'], 'readwrite');
//             var objectStore = transaction.objectStore('messages');
//             // Delete data using an index
//             var index = objectStore.index('conversation_id');
//             var deleteRequest = index.openCursor(IDBKeyRange.only(messages[0].conversation_id));
    
//             deleteRequest.onsuccess = function (event) {
//                 var cursor = event.target.result;
//                 if (cursor) {
//                     cursor.delete();
//                     cursor.continue();
//                 }
//             };
    
//             transaction.oncomplete = function () {
//                 //console.log('idx:Deletion completed');
//                 var request = indexedDB.open(dbName, 1);
//                 //console.log(request)
//                 // Handle database upgrade event
//                 request.onupgradeneeded = function (event) {
//                     var db = event.target.result;
//                     // Create an object store (similar to a table in a relational database)
//                     var objectStore = db.createObjectStore('messages', { keyPath: ['conversation_id', 'msg_id'] });
//                     // Create an index for searching by another property (e.g., 'name')
//                     objectStore.createIndex('conversation_id', 'conversation_id', { unique: false });
//                     objectStore.createIndex('msg_id', 'msg_id', { unique: false });
//                 };

//                 // Handle successful database opening
//                 request.onsuccess = async function (event) {
//                   //console.log(220,event)
//                     var db = event.target.result;
//                     // Start a transaction and get the object store
//                     var transaction = db.transaction(['messages'], 'readwrite');
//                     var objectStore = transaction.objectStore('messages');
//                     for(let i of messages){
//                       let d = await aesEncrypt(i);
//                       objectStore.put({msg_id:i.msg_id,conversation_id:i.conversation_id,value:d});
                      
//                     }

//                     // Commit the transaction
//                     transaction.oncomplete = function () {
//                         resolve(true)
//                         //console.log('idx:Data inserted successfully');
//                     };
//                 };
                
//             };
//             transaction.onerror = function (error) {
//               //console.log(error);
//               resolve(true)
//           };
      
//         };
//       });
//     }else{
//       return new Promise((resolve, reject) => {
//         var request = indexedDB.open(dbName, 1);
//           //console.log(request)
//           // Handle database upgrade event
//           request.onupgradeneeded = function (event) {
//               var db = event.target.result;
//               // Create an object store (similar to a table in a relational database)
//               var objectStore = db.createObjectStore('messages', { keyPath: ['conversation_id', 'msg_id'] });
//               // Create an index for searching by another property (e.g., 'name')
//               objectStore.createIndex('conversation_id', 'conversation_id', { unique: false });
//               objectStore.createIndex('msg_id', 'msg_id', { unique: false });
//           };

//           // Handle successful database opening
//           request.onsuccess = async function (event) {
//             //console.log(220,event)
//               var db = event.target.result;
//               // Start a transaction and get the object store
//               var transaction = db.transaction(['messages'], 'readwrite');
//               var objectStore = transaction.objectStore('messages');
//               for(let i of messages){
//                 let d = await aesEncrypt(i);
//                 objectStore.put({msg_id:i.msg_id,conversation_id:i.conversation_id,value:d});
//                 // objectStore.put(i);
                
//               }

//               // Commit the transaction
//               transaction.oncomplete = function () {
//                   resolve(true)
//                   //console.log('idx:Data inserted successfully');
//               };
//           };
//       });
//     }

    
//   }

  
    
  

// };

// export const getAllUsers = async () => {
//   const tx = (await db).transaction(objectStoreName, 'readonly');
//   const store = tx.objectStore(objectStoreName);
//   let users = await  store.getAll();
//   let data = [];
//   if(users.length > 0){
//     for(let i of users){
//       let val = await decryptedData(i.value);
//       data.push(val);
//     }
//   }

//   return data;
// };

// export const getUsersbyids = async (userIds) => {
//   // Create an array to store the fetched users
//   const users = [];

//   // Get a reference to the object store
//   const tx = (await db).transaction(objectStoreName, 'readonly');
//   const store = tx.objectStore(objectStoreName);

//   // Use IDBKeyRange to create a range of keys based on the provided user IDs
//   for (const id of userIds) {
//     const range = IDBKeyRange.only(id);
//     const cursor = await store.openCursor(range);

//     // If a cursor is found, add the user data to the users array
//     if (cursor) {
//       let val = await decryptedData(cursor.value.value);
//       users.push(val);
//       cursor.continue();
//     }
//   }

//   return users;
// };

const mykey = 'D1583ED51EEB8E58F2D3317F4839A';
const { return_data } = require('../config/config');
// return the user data from the session storage
export const getUser = () => {
  
  const userStr = localStorage.getItem('user');
  if (userStr){
    var userJson = JSON.parse(userStr);
    if(userJson.img){
      userJson.img = userJson.img.replace(/^(https?:\/\/)?[^/]+(:\d+)?/, return_data.FILE_SERVER.replace(/\/$/, ""))
    } 
    
    return userJson;
  } 
  else return null;
}
// return the user login data from the session storage
export const getSessionLoginData = () => {
  const loginData = localStorage.getItem('logindata');
  if (loginData) return JSON.parse(loginData);
  else return null;
}
export const setCurrentPagination = (pagidata) => {
  localStorage.setItem('current_pagination', JSON.stringify(pagidata));
}
export const getCurrentPagination = () => {
  const pagi = localStorage.getItem('current_pagination');
  if (pagi) return JSON.parse(pagi);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}
export const setOnlyTokenUser = (token,user) => {
  localStorage.setItem('token',token);
  localStorage.setItem('user',user);
}
 
// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('logindata');
  localStorage.removeItem('active_freeli_conv');
  
  let remember = localStorage.getItem('remember_me');
  let emailRe = localStorage.getItem('remember_email');
  let passwordRe = localStorage.getItem('remember_password');
  // localStorage.clear();
  if(remember){
    localStorage.setItem('remember_me',true)
    localStorage.setItem('remember_email',emailRe)
    localStorage.setItem('remember_password',passwordRe)
  }
  
}
 
// set the token and user from the session storage
export const setUserSession = (token, user,logindata) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('total_login', JSON.stringify(user.login_total));
  localStorage.setItem('logindata', JSON.stringify(logindata));
  //console.log(110,logindata)
}
export const setOnlyLoginData = (logindata) => {
  
  localStorage.setItem('logindata', JSON.stringify(logindata));
  
}
export const setOnlyUserData = (company_name) => {
  
  let user = localStorage.getItem('user');
  if(user){
    user = JSON.parse(user);
    user.company_name = company_name;
    localStorage.setItem('user', JSON.stringify(user));

  }
  
  
}

export const aesEncrypt = (data)=>{
  const cipher = CryptoJS.AES.encrypt(JSON.stringify(data), mykey).toString();
  return cipher.toString();
}
export const decryptedData = (data)=>{
  const cipher = CryptoJS.AES.decrypt(data, mykey);
  return JSON.parse(cipher.toString(CryptoJS.enc.Utf8));
}

export const fileExtension =(name, file_category = null)=>{
  if(!name) return 'other';
  //console.log(856,name,file_category);
  let ext = name?.split('.');
  ext = ext[ext.length - 1];
  ext = ext?.toLowerCase();
  let reext = '';

    switch (ext) {
          case 'apk':
            if(file_category === 'voice'){
              reext = 'voice';
              break;
            } 
          case 'ai':
          case 'mp3':
          case 'ogg':
          case 'doc':
          case 'DOC':    
          case 'docx':
          case 'DOCX':
          case 'exe':
          case 'indd':
          case 'js':
          case 'sql':
          case 'pdf':
          case 'Pdf':
          case 'PDF':
          case 'ppt':
          case 'pptx':
          case 'psd':
          case 'xls':
          case 'Xls':
          case 'XLS':
          case 'xlsx':
          case 'Xlsx':
          case 'XLSX':
          case 'zip':
          case 'rar':
        reext = ext;
        break;
      case '3gp':
      case 'avi':
      case 'flv':
      case 'm4v':
      case 'mov':
      case 'mpg':
      case 'mp4':
      case 'mkv':
      case 'wmv':
      case 'webm':
        reext = ext;
        break;
      case 'jpg':
      case 'JPG':
      case 'jpeg':
      case 'JPEG':
      case 'gif':
      case 'png':
      case 'PNG':
      case 'svg':
        reext = ext;
        break;
      default:
        reext = 'other';
    }
  return reext;
}

export const getThumImage = (l)=>{
  let ar = l.split('/');
  let n = 'thumb-'+ ar[ar.length -1];
  ar[ar.length -1] = n;
  return ar.join('/');
}
export const niceBytes = (x)=>{
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
   let l = 0, n = parseInt(x, 10) || 0;
   while(n >= 1024 && ++l){
       n = n/1024;
   }
   return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}


export const openFile = (file) => {
  localStorage.setItem('file_view',JSON.stringify(file))
  window.open('/file', "_blank")
};
export const openFilePOP = (v,props) => {
  ;
  props.set_popup_video(v.location);
	props.setPopup({type: 'playerPopup', data: true})
};

export const  get_user_details_by_id =(id)=>{
  let logindata = localStorage.getItem('logindata');
  logindata = JSON.parse(logindata);
  let data = false
  for (let u of logindata.all_users) {
    // //console.log(361,u)
    if (id == u.id) {
      data = {
        fullname:`${u.firstname} ${u.lastname}`
      }
    }
  }

  if(data){
    return data;
  }else{
    return {
      fullname:`Inactive User`
    }
  }
}

export const hasRepetitiveSpecialChars = (inputString) => {
  // Define a regular expression to match special characters
  const specialCharsRegex = /[^a-zA-Z0-9\s]/g;

  // Extract an array of special characters from the input string
  const specialCharsArray = inputString.match(specialCharsRegex);

  // Check for repetitive occurrences of special characters
  if (specialCharsArray) {
    for (let i = 0; i < specialCharsArray.length - 1; i++) {
      if (specialCharsArray[i] === specialCharsArray[i + 1]) {
        return true; // Repetitive special characters found
      }
    }
  }

  return false; // No repetitive special characters found
}
export const setReplyPagination = (pagidata) => { //change
  localStorage.setItem('reply_pagination', JSON.stringify(pagidata));
}
export const getReplyPagination = () => { //change
  const pagi = localStorage.getItem('reply_pagination');
  if (pagi) return JSON.parse(pagi);
  else return null;
}
export const setCookie = (cname, cvalue, exdays)=>{
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const  getCookie = (cname)=>{
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deepEqualTwoObject(obj1, obj2) {
  if (obj1 === obj2) return true; // Same reference
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key)
 || !deepEqualTwoObject(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}