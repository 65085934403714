import React from 'react';
import './maintenanceAlert.css'

const newFeatures = [
    "Faster Performance: Improved response times for large projects.",
    "Enhanced Security: Latest encryption protocols for data protection.",
    "New Features: Kanban view with unlimited tasks, notifications, and collaboration tools.",
    "Bug Fixes: Resolved known issues for a smoother user experience.",
    "EOD: End of Day report.",
    "Maintenance: Create a ticket.",
    "File Hub: Upgrade the file hub with a new design."
];

const upgradeSteps = [
    "Backup your data: Ensure all important files are safely backed up.",
    "Expect downtime: The upgrade process may cause temporary system unavailability.",
    "Notification: You will be notified once the upgrade is complete."
];

const helpContent = "If you encounter any issues or need assistance, feel free to contact our support team.";


const MaintenanceAlert = () => {
    return (
        <div className='maintenanceAlertCon'>
            {/* <div className='MaintenanceImageCon'>
                <div className='MaintenanceImage'></div>
                <p className="warningBody warningBodyPera">
                    Workfreeli is currently under maintenance. <br /> Please check back later.
                </p>
            </div> */}
            <div>
                <header>
                    <h1>WorkFreeli System Upgrade</h1>
                    <p>Your system is being upgraded to enhance features and security</p>
                </header>

                <div className="container">
                    <div className="section">
                        <h2>What's New?</h2>
                        <p >We are upgrading your system to the latest version with the following improvements:</p>
                        <ul className="steps">
                            {newFeatures.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>

                    <div className="section">
                        <h2>Upgrade Process</h2>
                        <p>Follow these simple steps during the upgrade:</p>
                        <ul className="steps">
                            {upgradeSteps.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                        <button className='maintenanceBtn'>Start Upgrade</button>
                    </div>

                    <div className="section">
                        <h2>Need Help?</h2>
                        <p>{helpContent}</p>
                        <button className='maintenanceBtn'>Contact Support</button>
                    </div>
                </div>

                <footer>
                    &copy; 2024 WorkFreeli. All rights reserved.
                </footer>
            </div>
        </div>
        // <div className='MaintenanceImageCon'>
        //     <div className='MaintenanceImage'></div>
        //     <p className="warningBody warningBodyPera">
        //         Workfreeli is currently under maintenance. <br /> Please check back later.
        //     </p>
        // </div>

    );
};

export default MaintenanceAlert;
